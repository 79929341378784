@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: pixel;
  src: url(../public/main_font.ttf);
}

@font-face {
  font-family: 'Inter-R';
  src: url(../public/fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: 'Inter-B';
  src: url(../public/fonts/Inter-Bold.ttf);
}

@layer base {
  /* Core */
  body {
    @apply
      bg-white dark:bg-c-main
  }
}

body {
  font-family: 'Inter-R';
}

.font-inter-b {
  font-family: 'Inter-B';
}

.divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid #45426D;
  opacity: 1;
}

.side-icon {
  display: flex;
  gap: 0.7rem;
    font-size: 18px;
}

/* .side-icon svg {
  margin-top: 5px;
} */